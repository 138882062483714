import React from 'react';
import { styled } from '@superset-ui/core';
import cls from 'classnames';
import './loader.styles.css';

export type PositionOption =
  | 'floating'
  | 'inline'
  | 'inline-centered'
  | 'normal';
export interface Props {
  position?: PositionOption;
  className?: string;
  image?: string;
}

const LoaderImgSvgStyled = styled.svg`
  z-index: 99;
  width: 40px;
  height: unset;
  position: relative;
  margin: 10px;
  overflow: visible !important;

  &.inline {
    margin: 0px;
    width: 30px;
  }

  &.inline-centered {
    margin: 0 auto;
    width: 30px;
    display: block;
  }

  &.floating {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

// @ts-ignore
export const Loader = props => (
  <LoaderImgSvgStyled
    className="base"
    fill="none"
    viewBox="0 0 104 103"
    {...props}
  >
    <g>
      <path
        className="triangle triangle1"
        id="triangle1"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle2"
        id="triangle2"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle3"
        id="triangle3"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle4"
        id="triangle4"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle5"
        id="triangle5"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle6"
        id="triangle6"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle7"
        id="triangle7"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle8"
        id="triangle8"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle9"
        id="triangle9"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
      <path
        className="triangle triangle10"
        id="triangle10"
        d="M2.37374 89.7706L51.1693 2.057L99.9648 89.7706H2.37374Z"
        stroke="url(#paint0_linear)"
        strokeWidth="1.5"
        // vectorEffect="non-scaling-stroke"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="17.9398"
        y1="-10.1828"
        x2="102.233"
        y2="90.1916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3ABE4" />
        <stop offset="0.272451" stopColor="#9CDBE4" />
        <stop offset="0.4375" stopColor="#D0ADE6" />
        <stop offset="0.723958" stopColor="#FFE9BD" />
        <stop offset="1" stopColor="#D098E5" />
      </linearGradient>
    </defs>
  </LoaderImgSvgStyled>
);

export default function Loading({
  position = 'floating',
  image,
  className,
}: Props) {
  return (
    <Loader
      role="status"
      aria-live="polite"
      aria-label="Loading"
      data-test="loading-indicator"
      className={cls('loading', 'base', position, className)}
      width={32}
      height={32}
    />
  );
}
